  <template>
  <div :class="cardBlockClasses">
    <div :class="cardClasses">
      <div class="label-wrapper">
        <ModelLabel
          :modelTypeId="data.registeredModelType.id"
        />
        <div
          v-if="data.variantId !== null"
          class="version-label">
          {{ data.variantId }}
        </div>
        <div
          v-if="isAdmin(state.users.user)"
          class="ui-reset-pipeline"
          @click="resetPipeline(data.id)"
          title="Перезапустить обработку модели"
        >
          <i class="pi pi-refresh"></i>
        </div>
        <div
          v-if="isAdmin(state.users.user)"
          class="ui-delete-model"
          @click="deleteModel(data.id)"
          title="Удалить модель"
        >
          <i class="pi pi-trash"></i>
        </div>
      </div>
      <div class="model-card-link-wrapper">
        <AdditionalInfoDropdown
          :data="additionalInfo"
          ref="addInfoRef"
          animateName="slide"
        />
        <AdditionalInfoDropdown
          :data="additionalTechInfo"
          ref="addTechInfoRef"
          animateName="slide"
        />
        <router-link
          v-if="!(data.status === ModelStatus.FAILED || data.status === ModelStatus.PROCESSING || data.status === ModelStatus.NEW)"
          class="model-card-link p-jc-center p-ai-center"
          target="_blank"
          :to="{ name: ROUTE_NAMES.PAGE_MODEL_VIEW, params: { id: data.modelUuid } }"
        >
          <div class="img-block">
            <img :src="getAuthImageForCard" alt="img">
          </div>
        </router-link>
        <div class="model-card-link disabled-link p-jc-center p-ai-center">
          <div class="img-block failed">
            <img src="@/assets/images/FAILED.svg" alt="img">
          </div>
          <div class="img-block processing">
            <img src="@/assets/images/PROCESSING.svg" alt="img">
            <template v-if="isAdmin(state.users.user)">
              <div class="status-line">{{ data.currentStepNameLocalized }}</div>
              <div class="status-line">{{ data.currentStep }}/{{ data.totalSteps }} ({{ data.currentStepService }})</div>
            </template>
          </div>
        </div>
      </div>
      <div class="info-block">
        <div class="info-line">
          <div class="main-info-line">
            <div class="info-wrapper">
              <router-link
                class="value"
                target="_blank"
                :to="{ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: data.requestId }}"
              >
                <div class="ui-tooltip">
                  <div class="value-line">
                    {{ getModelValue(data.requestId) }}
                    <div class="tooltip-content">{{ getModelValue(data.requestId) }}</div>
                  </div>
                </div>
              </router-link>
              <div class="text">Номер Заявки</div>
            </div>
            <div class="info-wrapper">
              <div class="value">
                <div class="ui-tooltip">
                  <div class="value-line p-text-right">
                    {{ getModelValue(data.patentId) }}
                    <div class="tooltip-content">{{ getModelValue(data.patentId) }}</div>
                  </div>
                </div>
              </div>
              <div class="text p-text-right">{{ getTypeNameByModelType(data.registeredModelType.id) }}</div>
            </div>
          </div>
          <div class="main-info-line additional" v-if="additionalAttr">
            <div class="info-wrapper">
              <div class="value">
                <div class="ui-tooltip">
                  <div class="value-line">
                    {{ additionalAttr.text }}
                    <div class="tooltip-content">{{ additionalAttr.text }}</div>
                  </div>
                </div>
              </div>
              <div class="text">{{ additionalAttr.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-block">
        <div class="add-info-wrapper">
          <div
            @click="toggleDropdown"
            class="add-info-button"
            v-tooltip.top="'О заявке'"
          >
            <i class="pi pi-info-circle"></i>
          </div>
          <div
            @click="toggleTechDropdown"
            class="add-info-button"
            v-tooltip.top="'Техническая информация'"
          >
            <img alt="img" src="@/assets/icons/tech_info.svg" />
          </div>
        </div>
        <div
          v-if="data.status === ModelStatus.CONVERTED"
          class="button-wrapper"
        >
          <!--TODO изменить v-if, когда будет поиск по PRC-->
          <BaseIconButton
            v-if="!(data.modelFileFormat.id === ModelFileFormats.PRC || data.registeredModelType.id === RegisteredModelTypes.PARTICLE)"
            tool-tip-text="Перейти к поиску"
            @clicked="clickCompare"
          >
            <template v-slot:button-image>
              <img alt="img" src="@/assets/icons/search_models.svg">
            </template>
          </BaseIconButton>
          <BaseIconButton
            tool-tip-text="Перейти к просмотру"
            :href="{ name: ROUTE_NAMES.PAGE_MODEL_VIEW, params: { id: data.modelUuid } }"
            is-target-blank
          >
            <template v-slot:button-image>
              <img alt="img" src="@/assets/icons/open_model.svg">
            </template>
          </BaseIconButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./BaseModelCard.ts" lang="ts"/>
<style src="./BaseModelCard.scss" lang="scss"/>
