import { computed, defineComponent, PropType } from 'vue';
import type { TModelData } from '@/types/modelType';
import BaseModelCard from '@/components/Model/ModelCards/BaseModelCard/BaseModelCard.vue';
import { createdAt } from '@/components/Model/ModelCards/utils/createdAt';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import { isAdmin, isInternalUser } from '@/utils/rolesUtils';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ModelCardInvention',

  components: {
    BaseModelCard
  },

  props: {
    data: {
      type: Object as PropType<TModelData>,
      required: true
    }
  },

  emits: ['clickCompare'],

  setup(props, { emit }) {
    const { state } = useStore();
    const additionalTechInfo = computed(() => {
      const info = [
        {
          name: 'Дата поступления модели',
          text: `${createdAt(props.data.createdAt)} МСК`
        },
        {
          name: 'Пользователь, загрузивший модель',
          text: (props.data.author?.login && getModelValue(props.data.author?.login))
        },
        {
          name: 'Имя файла',
          text: (props.data.fileName)
        }
      ];
      if (isAdmin(state.users.user) || isInternalUser(state.users.user)) {
        info.push({
          name: 'Универсальный уникальный идентификатор',
          text: (props.data.modelUuid)
        });
      }
      return info;
    });

    const additionalInfo = computed(() => {
      return [
        {
          name: 'Номер Заявки',
          text: props.data.requestId
        },
        {
          name: 'Патент',
          text: props.data.patentId
        }
      ];
    });

    const clickCompare = (item: { id: string; uuid: string }) => {
      emit('clickCompare', item);
    };

    return { additionalInfo, clickCompare, additionalTechInfo };
  }
});
