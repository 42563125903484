import { computed, defineComponent, onMounted, PropType } from 'vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { STATISTICS_ACTIONS_TYPE } from '@/store/names/action.name';
import { RegisteredModelTypes } from '@/types/modelType';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import { getDeclensionWord } from '@/utils/declensions';

export default defineComponent({
  name: 'InfoBoard',

  components: {
    BaseLoader
  },

  props: {
    showedModelTypes: {
      type: Array as PropType<RegisteredModelTypes[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const { dispatch, state } = useStore();

    const boardClassName = computed(() => {
      switch (JSON.stringify(props.showedModelTypes)) {
        case JSON.stringify([RegisteredModelTypes.INDUSTRIAL_MODEL]):
          return 'industrial';
        case JSON.stringify([RegisteredModelTypes.PARTICLE]):
          return 'particle';
        case JSON.stringify([RegisteredModelTypes.TRADEMARK]):
          return 'trademark';
        case JSON.stringify([RegisteredModelTypes.UTILITY_MODEL, RegisteredModelTypes.INVENTION]):
          return 'invention';
        case JSON.stringify([RegisteredModelTypes.INVENTION]):
          return 'invention';
        case JSON.stringify([RegisteredModelTypes.UTILITY_MODEL]):
          return 'invention';
        default: return 'invention';
      }
    });

    const oisTypeName = computed(() => {
      switch (JSON.stringify(props.showedModelTypes)) {
        case JSON.stringify([RegisteredModelTypes.INDUSTRIAL_MODEL]):
          return ['ПО', 'патентов'];
        case JSON.stringify([RegisteredModelTypes.PARTICLE]):
          return ['MOL', 'патентов'];
        case JSON.stringify([RegisteredModelTypes.TRADEMARK]):
          return ['ТЗ', 'свидетельств'];
        case JSON.stringify([RegisteredModelTypes.UTILITY_MODEL, RegisteredModelTypes.INVENTION]):
          return ['ИзПМ', 'патентов'];
        case JSON.stringify([RegisteredModelTypes.INVENTION]):
          return ['ИзПМ', 'патентов'];
        case JSON.stringify([RegisteredModelTypes.UTILITY_MODEL]):
          return ['ИзПМ', 'патентов'];
        default: return ['ИзПМ', 'патентов'];
      }
    });

    const requestCount = computed(() => {
      if (props.showedModelTypes[0] === RegisteredModelTypes.INVENTION || props.showedModelTypes[0] === RegisteredModelTypes.UTILITY_MODEL) {
        return (
          (state.statistics?.content[1]?.requestsCount || 0) +
          (state.statistics?.content[2]?.requestsCount || 0)
        );
      } else {
        return state.statistics?.content[props.showedModelTypes[0]]?.requestsCount || 0;
      }
    });

    const modelsCount = computed(() => {
      if (props.showedModelTypes[0] === RegisteredModelTypes.INVENTION || props.showedModelTypes[0] === RegisteredModelTypes.UTILITY_MODEL) {
        return (
          (state.statistics?.content[1]?.errorModelsCount || 0) +
          (state.statistics?.content[1]?.successModelsCount || 0) +
          (state.statistics?.content[1]?.processingModelsCount || 0) +
          (state.statistics?.content[2]?.errorModelsCount || 0) +
          (state.statistics?.content[2]?.successModelsCount || 0) +
          (state.statistics?.content[2]?.processingModelsCount || 0)
        );
      } else {
        return (
          (state.statistics?.content[props.showedModelTypes[0]]?.errorModelsCount || 0) +
          (state.statistics?.content[props.showedModelTypes[0]]?.successModelsCount || 0) +
          (state.statistics?.content[props.showedModelTypes[0]]?.processingModelsCount || 0)
        );
      }
    });

    const patientCount = computed(() => {
      if (props.showedModelTypes[0] === RegisteredModelTypes.INVENTION || props.showedModelTypes[0] === RegisteredModelTypes.UTILITY_MODEL) {
        return (
          (state.statistics?.content[1]?.patentsCount || 0) +
          (state.statistics?.content[2]?.patentsCount || 0)
        );
      } else {
        return state.statistics?.content[props.showedModelTypes[0]]?.patentsCount || 0;
      }
    });

    const errorModelsCount = computed(() => {
      if (props.showedModelTypes[0] === RegisteredModelTypes.INVENTION || props.showedModelTypes[0] === RegisteredModelTypes.UTILITY_MODEL) {
        return (
          (state.statistics?.content[1]?.errorModelsCount || 0) +
          (state.statistics?.content[2]?.errorModelsCount || 0)
        );
      } else {
        return state.statistics?.content[props.showedModelTypes[0]]?.errorModelsCount || 0;
      }
    });

    const inventionCount = computed(() => {
      return (state.statistics?.content[RegisteredModelTypes.INVENTION]?.requestsCount || 0);
    });

    const utilityModelCount = computed(() => {
      return (state.statistics?.content[RegisteredModelTypes.UTILITY_MODEL]?.requestsCount || 0);
    });

    const inventionPatientCount = computed(() => {
      return (state.statistics?.content[RegisteredModelTypes.INVENTION]?.patentsCount || 0);
    });

    const utilityPatientCount = computed(() => {
      return (state.statistics?.content[RegisteredModelTypes.UTILITY_MODEL]?.patentsCount || 0);
    });

    onMounted(() => {
      dispatch(`${MODULES_NAMES.STATISTICS}/${STATISTICS_ACTIONS_TYPE.GET_ALL_STATISTICS}`);
    });

    return {
      state,
      inventionCount,
      utilityModelCount,
      oisTypeName,
      boardClassName,
      getDeclensionWord,
      requestCount,
      modelsCount,
      patientCount,
      RegisteredModelTypes,
      errorModelsCount,
      inventionPatientCount,
      utilityPatientCount
    };
  }
});
