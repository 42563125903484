import { computed, defineComponent, ref } from 'vue';
import BaseTable from '@/components/BaseComponents/BaseTable/BaseTable.vue';
import { useStore } from 'vuex';
import { createdAt } from '@/components/Model/ModelCards/utils/createdAt';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import BaseAuthCardImg from '@/components/BaseComponents/BaseAuthCardImg/BaseAuthCardImg.vue';
import ROUTE_NAMES from '@/router/route.names.json';
import { ModelFileFormats, ModelStatus, RegisteredModelTypes, TModelData } from '@/types/modelType';
import ModelLabel from '@/components/Model/ModelLabel/ModelLabel.vue';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import { getMkpoClassGrandParent, getMkpoClassParent, getMkpoName } from '@/utils/getMkpoClasses';
import { getTypeNameByModelType } from '@/utils/getNameByModelType';
import { isAdmin, isInternalUser } from '@/utils/rolesUtils';

export default defineComponent({
  name: 'ModelsTable',

  components: {
    BaseTable,
    BaseIconButton,
    BaseAuthCardImg,
    ModelLabel
  },

  emits: ['clickCompare'],

  setup(_, { emit }) {
    const { state } = useStore();
    const modelTableRef = ref();

    const columns = computed(() => {
      return [
        { title: 'Превью', colspan: 1 },
        { title: 'Номер заявки', colspan: 1 },
        { title: getTypeNameByModelType(state.models?.modelsData?.content[0]?.registeredModelType?.id), colspan: 1 },
        { title: 'Дата поступления модели', colspan: 1 },
        { title: '', colspan: 1 },
        { title: '', colspan: 1 }
      ];
    });

    const rows = computed(() => {
      return state.models.modelsData.content.length ? state.models.modelsData.content?.map((item: TModelData) => {
        return { cells: [item, item, item, item, item, item] };
      }) : [];
    });

    const toggleAddInfo = (index: number) => {
      modelTableRef.value.isVisibleSecondBlock[index] && modelTableRef.value.toggleVisibleSecondBlock(index);
      modelTableRef.value.toggleVisible(index);
    };

    const toggleAddTechInfo = (index: number) => {
      modelTableRef.value.isVisibleBlock[index] && modelTableRef.value.toggleVisible(index);
      modelTableRef.value.toggleVisibleSecondBlock(index);
    };

    const clickCompare = (item: TModelData) => {
      emit('clickCompare', { id: item.id, uuid: item.modelUuid });
    };

    const additionalInfo = (data: TModelData) => {
      return data.registeredModelType?.id === RegisteredModelTypes.INDUSTRIAL_MODEL
        ? [
          { label: 'Класс МКПО', value: (data.mkpoClassGrandParent && getMkpoClassGrandParent(data)) },
          { label: 'Подкласс МКПО', value: (data.mkpoClassParent && getMkpoClassParent(data)) },
          { label: 'Наименование', value: (data.mkpoClass && getMkpoName(data)) },
          { label: 'Номер заявки', value: (data.requestId && getModelValue(data.requestId)) },
          {
            label: getTypeNameByModelType(state.models?.modelsData?.content[0]?.registeredModelType?.id),
            value: (data.patentId && getModelValue(data.patentId))
          }
        ]
        : [
          { label: 'Номер заявки', value: (data.requestId && getModelValue(data.requestId)) },
          {
            label: getTypeNameByModelType(state.models?.modelsData?.content[0]?.registeredModelType?.id),
            value: (data.patentId && getModelValue(data.patentId))
          }
        ];
    };

    const additionalTechInfo = (data: TModelData) => {
      const techData = [
        { label: 'Дата поступления модели', value: `${createdAt(data?.createdAt)} МСК` },
        { label: 'Пользователь, загрузивший модель', value: data?.author?.login },
        { label: 'Имя файла', value: (data.fileName) }
      ];
      if (isAdmin(state.users.user) || isInternalUser(state.users.user)) {
        techData.push({
          label: 'Универсальный уникальный идентификатор',
          value: (data.modelUuid)
        });
      }
      return techData;
    };

    return {
      getModelValue,
      toggleAddTechInfo,
      additionalTechInfo,
      additionalInfo,
      clickCompare,
      ModelStatus,
      ModelFileFormats,
      ROUTE_NAMES,
      state,
      createdAt,
      toggleAddInfo,
      modelTableRef,
      RegisteredModelTypes,
      columns,
      rows
    };
  }
});
