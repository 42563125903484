<template>
  <BaseDropdownMenu :animate-name="animateName" ref="addInfoRef">
    <template v-slot:content>
      <template
        v-for="(item, index) in data"
        :key="index"
      >
        <div
          class="add-info-line"
          v-if="!!item.text"
        >
          <div class="add-info-name">{{ item.name }}</div>
          <div class="add-info-text">{{ item.text }}</div>
        </div>
      </template>
    </template>
  </BaseDropdownMenu>
</template>

<script src="./AdditionalInfoDropdown.ts" lang="ts"/>
<style src="./AdditionalInfoDropdown.scss" lang="scss" scoped/>
