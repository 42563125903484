<template>
  <InfoHeadForPage
    page-name="Каталог"
  >
    <template v-slot:content-top>
      <div class="head-info-content">
        <div class="model-type-text ui-title-medium">
          {{ pageName }}
        </div>
        <div>
          <InfoBoard
            :showed-model-types="showedModelTypes"
            :is-loading="state.models.isLoadingList"
          />
        </div>
      </div>
    </template>
    <template v-slot:content-bottom>
      <div class="head-info-content head-info-content-second">
        <div class="ui-input-filters-block">
          <InputsFilters/>
        </div>
        <div class="showed-wrapper">
          <slot name="filter-slot">
          </slot>
            <ChangeShowTypeButton />
        </div>
      </div>
    </template>
  </InfoHeadForPage>
  <slot name="content">
    <div class="p-grid model-list-paginator-grid">
      <div class="p-col-12">
        <BasePaginator
          :total-records="modelsCount"
          @changed="onChangePagination($event)"
          :rows-per-page-options="defaultPaginatorSettings.selectSize"
          :size-rows="state.paginator.paginator.size"
          :first-page-prop="state.paginator.paginator.page"
          :total-pages="state.models?.modelsData?.totalPages"
        />
      </div>
    </div>
    <ModelList
      v-if="state.paginator.showType === ShowType.CARDS"
      :data="state.models.modelsData.content"
      :is-loading="state.models.isLoadingList"
      @clickCompare="clickCompare($event)"
    />
    <ModelsTable
      v-if="state.paginator.showType === ShowType.TABLE"
      @clickCompare="clickCompare($event)"
    />
  </slot>
  <div
    class="p-col-12 ui-no-found-content"
    v-if="!state.models.modelsData.totalElements && !state.models.isLoadingList"
  >
    <img alt="not found" src="@/assets/images/no_results.svg"/>
    <div class="ui-title-large">моделей по вашему запросу не найдено</div>
    <div>
      <div class="bold-text">
        Рекомендации:
      </div>
      <div class="text">
        1. Убедитесь, что фильтры заполнены правильно<br>
        2. Попробуйте использовать для отображения другой период загрузки
      </div>
    </div>
  </div>
  <BasePopUp
    className="start-search-modal"
    title="Поиск похожих моделей"
    ref="startSearchPopUpRef"
  >
    <template v-slot:content>
      <FormStartSearch
        :model-id="compareData.id"
        :model-uuid="compareData.uuid"
        @closePopup="togglePopup"
      />
    </template>
  </BasePopUp>
  <ScrollTopButton />
</template>

<script src="./BasePageList.ts" lang="ts"/>
<style src="./BasePageList.scss" lang="scss"/>
