<template>
  <BasePageList
    page-name="Промышленные образцы"
    :showed-model-types="modelTypes"
  >
    <template v-slot:filter-slot>
      <MkpoFilters />
    </template>
  </BasePageList>
</template>

<script src="./PageIndustrialList.ts" lang="ts"/>
<style src="./PageIndustrialList.scss" lang="scss"/>
