<template>
  <teleport to="#app-content">
    <BaseButton
      v-if="isShow"
      prime-icon="pi-arrow-up"
      class-name="scroll-top-button"
      @clicked="scrollTop"
    />
  </teleport>
</template>

<script src="./ScrollTopButton.ts" lang="ts"/>
<style src="./ScrollTopButton.scss" lang="scss"/>
