import { defineComponent, PropType, ref } from 'vue';
import BaseDropdownMenu from '@/components/BaseComponents/BaseDropdownMenu/BaseDropdownMenu.vue';
import type { TAddInfo } from '@/types/modelType';

export default defineComponent({
  name: 'AdditionalInfoDropdown',

  components: {
    BaseDropdownMenu
  },

  props: {
    data: {
      type: Object as PropType<TAddInfo[]>
    },
    animateName: {
      type: String
    }
  },

  setup() {
    const addInfoRef = ref();

    return { addInfoRef };
  }
});
