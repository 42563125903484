import { defineComponent, ref } from 'vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';

export default defineComponent({
  name: 'ScrollTopButton',

  components: {
    BaseButton
  },

  setup() {
    const isShow = ref(false);

    const scrollTop = () => {
      if (window.pageYOffset > 0) {
        window.scrollBy(0, -30);
        setTimeout(scrollTop, 2);
      }
    };

    const trackScroll = () => {
      const scrolled = window.pageYOffset;
      const offsetConst = 200;

      if (scrolled > offsetConst) {
        isShow.value = true;
      }
      if (scrolled < offsetConst) {
        isShow.value = false;
      }
    };

    window.addEventListener('scroll', trackScroll);

    return { scrollTop, isShow };
  }
});
