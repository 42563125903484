import { defineComponent, onMounted, ref } from 'vue';
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue';
import BaseForm from '@/components/BaseComponents/BaseForm/BaseForm.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import MkpoDropdownGroup from '@/components/Forms/MkpoDropdownGroup/MkpoDropdownGroup.vue';
import { mixed, object } from 'yup';
import { useForm } from 'vee-validate';
import type { TmkpoClassId } from '@/store/modules/models/state';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { useStore } from 'vuex';
import { ModelsHttpService } from '@/services/modelsService';
import { setArrWithIndex } from '@/components/Forms/MkpoDropdownGroup/utils/setArrWithIndex';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';

export default defineComponent({
  name: 'MkpoFiltersForm',
  components: {
    BaseDropdown,
    BaseForm,
    BaseButton,
    MkpoDropdownGroup
  },

  emits: ['filterChanged'],

  setup(_, { emit }) {
    const possibleValues = ref();
    const mkpoGroupRef = ref();
    const isLoaded = ref(false);
    const modelsHttpService = new ModelsHttpService();
    const schema = object({
      mkpoClassGrandParent: mixed(),
      mkpoClassParent: mixed(),
      mkpoClassId: mixed()
    });

    const {
      handleSubmit,
      handleReset,
      values,
      setValues
    } = useForm({
      validationSchema: schema
    });

    const { state, dispatch } = useStore();

    const commitChanges = (type: string, value: TmkpoClassId) => {
      const payload = { ...state.models.filters };
      payload[type] = value;
      dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.SET_FILTERS}`, payload);
    };

    const resetForm = () => {
      handleReset();
      return commitChanges('mkpoClassId', null);
    };

    const changeMkpoValues = (changedClass: Array<keyof typeof values>) => {
      changedClass.forEach((item: keyof typeof values) => {
        values[item] = undefined;
      });
    };

    onMounted(async() => {
      if (state.models.filters.mkpoClassId) {
        const { data } = await modelsHttpService.getMkpoValuesForFilter(state.models.filters.mkpoClassId);
        const setLvl1 = setArrWithIndex(data['1']);
        const setLvl2 = setArrWithIndex(data['2']);
        const chosenLvl1 = setArrWithIndex([data.selected['1']])[0];
        const chosenLvl2 = setArrWithIndex([data.selected['2']])[0];
        setValues({
          mkpoClassGrandParent: chosenLvl1,
          mkpoClassParent: chosenLvl2,
          mkpoClassId: data.selected['3']
        });
        possibleValues.value = {
          lvl1: setLvl1,
          lvl2: setLvl2,
          lvl3: data['3']
        };
      }
      isLoaded.value = true;
    });

    const handleSubmitForm = handleSubmit(async(values) => {
      emit('filterChanged');
      if (values.mkpoClassId) {
        return commitChanges('mkpoClassId', values.mkpoClassId.id);
      }
      if (values.mkpoClassParent) {
        return commitChanges('mkpoClassId', values.mkpoClassParent.id);
      }
      if (values.mkpoClassGrandParent) {
        return commitChanges('mkpoClassId', values.mkpoClassGrandParent.id);
      }
    });

    return {
      isLoaded,
      mkpoGroupRef,
      changeMkpoValues,
      possibleValues,
      commitChanges,
      resetForm,
      handleSubmit,
      handleSubmitForm,
      handleReset,
      values
    };
  }
});
