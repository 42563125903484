import { defineComponent, computed, PropType } from 'vue';
import { useStore } from 'vuex';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import ModelCardInvention from '@/components/Model/ModelCards/ModelCardInvention/ModelCardInvention.vue';
import ModelCardIndustrial from '@/components/Model/ModelCards/ModelCardIndustrial/ModelCardIndustrial.vue';
import ModelCardTrademark from '@/components/Model/ModelCards/ModelCardTrademark/ModelCardTrademark.vue';
import { RegisteredModelTypes, TModelData } from '@/types/modelType';

export default defineComponent({
  name: 'ModelList',

  components: {
    ModelCardInvention,
    ModelCardIndustrial,
    ModelCardTrademark,
    BaseLoader
  },

  props: {
    data: {
      type: Array as PropType<TModelData[]>
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },

  setup(props, { emit }) {
    const { state } = useStore();

    const models = computed(() => {
      return props.data;
    });

    const clickCompare = (item: { id: string; uuid: string }) => {
      emit('clickCompare', item);
    };

    return {
      RegisteredModelTypes,
      clickCompare,
      models,
      state
    };
  }
});
