<template>
  <div
    :class="`${!state.models.modelsData.totalElements && !state.models.isLoadingList && 'no-height'} p-grid ui-p-grid`"
  >
    <BaseLoader v-if="isLoading"/>
    <template
      v-for="(item, index) in models"
      :key="index+item.modelUuid"
    >
      <ModelCardInvention
        :data="item"
        v-if="item.registeredModelType.id === RegisteredModelTypes.INVENTION || item.registeredModelType.id === RegisteredModelTypes.UTILITY_MODEL"
        @clickCompare="clickCompare($event)"
      />
      <ModelCardIndustrial
        :data="item"
        v-if="item.registeredModelType.id === RegisteredModelTypes.INDUSTRIAL_MODEL"
        @clickCompare="clickCompare($event)"
      />
      <ModelCardTrademark
        :data="item"
        v-if="item.registeredModelType.id === RegisteredModelTypes.TRADEMARK"
        @clickCompare="clickCompare($event)"
      />
      <ModelCardInvention
        :data="item"
        v-if="item.registeredModelType.id === RegisteredModelTypes.PARTICLE"
        @clickCompare="clickCompare($event)"
      />
    </template>
  </div>
</template>

<script src="./ModelList.ts" lang="ts"/>
<style src="./ModelList.scss" lang="scss"/>
