<template>
  <div class="inputs-block">
    <BaseInputText
      class-name="request-input"
      name="search"
      icon="pi-search"
      placeholder="Поиск"
      :value="state.models.filters.query"
      @input="changeFilterQuery($event)"
      @reset="resetFilterQuery()"
      has-remove-icon
    />
    <BaseInputCalendar
      name="date"
      placeholder="Период загрузки"
      @changed="changeFilterDate($event)"
      :value="state.models.filters.date"
      has-remove-icon
      :chipsList="chipsList"
    />
  </div>
</template>

<script src="./InputFilters.ts" lang="ts"/>
<style src="./InputFilter.scss" lang="scss"/>
