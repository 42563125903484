<template>
  <div class="mkpo-filter-btn-wrapper" id="mkpo-wrapper">
    <BaseButton
      @clicked="togglePopOver"
      label="Фильтры"
      iconPosition="left"
      primeIcon="pi-filter"
    />
    <BasePopOver
      ref="mkpoPopOverRef"
      append-element-id="#mkpo-wrapper"
      class-name="filter-popover"
    >
      <template v-slot:popover-content>
        <MkpoFiltersForm @filterChanged="togglePopOver" />
      </template>
    </BasePopOver>
  </div>
</template>

<script src="./MkpoFilters.ts" lang="ts"/>
<style src="./MkpoFilters.scss" lang="scss"/>
