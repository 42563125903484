import { computed, defineComponent, onMounted, PropType, watch, onUnmounted, ref } from 'vue';
import InfoHeadForPage from '@/components/InfoHeadForPage/InfoHeadForPage.vue';
import BasePaginator from '@/components/BaseComponents/BasePaginator/BasePaginator.vue';
import BaseInputCalendar from '@/components/BaseComponents/BaseInputCalendar/BaseInputCalendar.vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import InputsFilters from '@/components/Forms/InputFilters/InputFilters.vue';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_MUTATIONS_TYPE, PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import { useStore } from 'vuex';
import type { TEventPaginator } from '@/types/eventTypes';
import { RegisteredModelTypes } from '@/types/modelType';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';
import { AbortError } from '@/services/utils/errors/abortError';
// import appendToastMessage from '@/utils/appendToastMessage';
// import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import _ from 'lodash';
import setPaginatorAndFiltersFromQuery from '@/components/Model/utils/setPaginatorAndFiltersFromQuery';
import { useRoute } from 'vue-router';
import ModelList from '@/components/Model/ModelList/ModelList.vue';
import ChangeShowTypeButton from '@/components/ChangeShowTypeButton/ChangeShowTypeButton.vue';
import { ShowType } from '@/store/modules/paginator/state';
import ModelsTable from '@/components/Model/ModelsTable/ModelsTable.vue';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import FormStartSearch from '@/components/Forms/FormStartSearch/FormStartSearch.vue';
import InfoBoard from '@/components/Model/InfoBoard/InfoBoard.vue';
import ScrollTopButton from '@/components/ScrollTopButton/ScrollTopButton.vue';

export default defineComponent({
  name: 'BasePageList',

  components: {
    InfoHeadForPage,
    BasePaginator,
    BaseInputCalendar,
    BaseIconButton,
    InputsFilters,
    ModelList,
    ChangeShowTypeButton,
    ModelsTable,
    BasePopUp,
    FormStartSearch,
    InfoBoard,
    ScrollTopButton
  },

  props: {
    pageName: {
      type: String,
      default: 'Все'
    },
    showedModelTypes: {
      type: Array as PropType<RegisteredModelTypes[]>,
      required: true
    }
  },

  setup(props) {
    const route = useRoute();
    const { state, commit, dispatch } = useStore();
    const startSearchPopUpRef = ref();

    const getModelsList = async() => {
      try {
        await dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.FETCH_MODELS_LIST}`, props.showedModelTypes);
      } catch (err) {
        if (err.name !== AbortError.name) {
          // TODO временно убрал
          // appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
      }
    };

    const debouncedGetModelsList = _.debounce(getModelsList, 300);

    const updateList = async() => {
      await debouncedGetModelsList();
    };

    onMounted(async() => {
      setPaginatorAndFiltersFromQuery(route.query);
      await updateList();
    });

    watch(() => state.models.filters, () => {
      updateList();
    });

    watch(() => state.paginator.paginator, () => {
      updateList();
    });

    const modelsCount = computed(() => {
      return state.models?.modelsData?.totalElements || 0;
    });

    const onChangePagination = (event: TEventPaginator) => {
      commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
        size: event.rows,
        page: event.page
      });
    };

    onUnmounted(() => {
      commit(`${MODULES_NAMES.MODELS}/${MODELS_MUTATIONS_TYPE.RESET_FILTERS}`);
    });

    const togglePopup = () => {
      startSearchPopUpRef.value.handleModal();
    };

    const compareData = ref({ id: '', uuid: '' });
    const clickCompare = (item: { id: string; uuid: string }) => {
      compareData.value = {
        id: item.id,
        uuid: item.uuid
      };
      togglePopup();
    };

    return {
      togglePopup,
      compareData,
      startSearchPopUpRef,
      clickCompare,
      state,
      ShowType,
      modelsCount,
      defaultPaginatorSettings,
      onChangePagination
    };
  }
});
