import { defineComponent } from 'vue';
import BasePageList from '@/components/Model/BasePageList/BasePageList.vue';
import { RegisteredModelTypes } from '@/types/modelType';
import MkpoFilters from '@/components/Model/PageIndustrialList/components/MkpoFilters/MkpoFilters.vue';

export default defineComponent({
  name: 'PageInventionList',

  components: {
    MkpoFilters,
    BasePageList
  },

  setup() {
    const modelTypes = [RegisteredModelTypes.INDUSTRIAL_MODEL];
    return { modelTypes };
  }
});
