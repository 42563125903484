<template>
  <div :class="`board-block ${boardClassName}`">
    <BaseLoader
      backgroundColorRGBA="rgba(1,1,1, .4)"
      v-if="isLoading || state.statistics.isLoading"
    />
    <div v-if="showedModelTypes[0] === RegisteredModelTypes.PARTICLE">
      <span>Всего заявок на ИзПМ с молекулами в трехмерном виде: </span>
      <span class="main-color">{{ requestCount }}</span>
      <span>, выдано {{ oisTypeName[1] }}:</span>
      <span class="main-color">{{ patientCount }}</span>
    </div>
    <div class="statistic-block" v-else>
      <span>Всего заявок на {{ oisTypeName[0] }} с трехмерными моделями:</span>
      <span class="main-color">{{ requestCount }}</span>
      <span>, выдано {{ oisTypeName[1] }}:</span>
      <span class="main-color">{{ patientCount }}</span>
    </div>
    <div class="statistic-block">
      <span class="invention-add-statistic">
        • ИЗ c трехмерными моделями: <b>{{ inventionCount }}</b>, выдано патентов: <b>{{ inventionPatientCount }}</b> <br>
        • ПМ с трехмерными моделями: <b>{{ utilityModelCount }}</b>, выдано патентов: <b>{{ utilityPatientCount }}</b>
      </span>
    </div>
    <div class="statistic-block" v-if="showedModelTypes[0] === RegisteredModelTypes.PARTICLE">
      <span>Молекул в трехмерном виде (MOL) в заявках:</span>
      <span
        v-tooltip.top="'Заявка может содержать больше одной трехмерной модели.'"
        class="main-color tooltip-icon"
      >?</span>
      <span>:</span>
      <span class="main-color">{{ modelsCount }}</span>
      <span>, из них с ошибками загрузки:</span>
      <span class="main-color">{{ errorModelsCount }}</span>
    </div>

    <div class="statistic-block" v-else>
      <span>Трехмерных моделей в заявках</span>
      <span
        v-tooltip.top="'Заявка может содержать больше одной трехмерной модели.'"
        class="main-color tooltip-icon"
      >?</span>
      <span>:</span>
      <span class="main-color">{{ modelsCount }}</span>
      <span>, из них с ошибками загрузки:</span>
      <span class="main-color">{{ errorModelsCount }}</span>
    </div>
  </div>
</template>

<script src="./InfoBoard.ts" lang="ts"/>
<style src="./InfoBoard.scss" lang="scss"/>
