<template>
  <div class="ui-show-type-buttons">
    <BaseIconButton
      tool-tip-text="Отображение карточками"
      :isActive="isActiveShowedType(ShowType.CARDS)"
      @clicked="changeShowType(ShowType.CARDS)"
      :disabled="disabled"
    >
      <template v-slot:button-image>
        <i class="pi pi-th-large"></i>
      </template>
      <template v-slot:button-image-active>
        <i class="pi pi-th-large"></i>
      </template>
    </BaseIconButton>
    <BaseIconButton
      tool-tip-text="Отображение таблицей"
      :isActive="isActiveShowedType(ShowType.TABLE)"
      @clicked="changeShowType(ShowType.TABLE)"
      :disabled="disabled"
    >
      <template v-slot:button-image>
        <i class="pi pi-bars"></i>
      </template>
      <template v-slot:button-image-active>
        <i class="pi pi-bars"></i>
      </template>
    </BaseIconButton>
  </div>
</template>

<script src="./ChangeShowTypeButton.ts" lang="ts"/>
<style src="./ChangeShowTypeButton.scss" lang="scss"/>
