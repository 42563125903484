import { computed, defineComponent, PropType, ref } from 'vue';
import type { TModelData, TAddInfo } from '@/types/modelType';
import { ModelStatus, ModelFileFormats, RegisteredModelTypes } from '@/types/modelType';
import ROUTE_NAMES from '@/router/route.names.json';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import ModelLabel from '@/components/Model/ModelLabel/ModelLabel.vue';
import AdditionalInfoDropdown from '@/components/Model/AdditionalInfoDropdown/AdditionalInfoDropdown.vue';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import { ModelsHttpService } from '@/services/modelsService';
import appendToastMessage from '@/utils/appendToastMessage';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox/BaseCheckbox.vue';
import BaseAuthCardImg from '@/components/BaseComponents/BaseAuthCardImg/BaseAuthCardImg.vue';
import { getAuthImage } from '@/utils/getAuthImage';
import { getModelImageUrl } from '@/components/Model/utils/getImageUrl';
import { getTypeNameByModelType } from '@/utils/getNameByModelType';
import { useStore } from 'vuex';
import { isAdmin } from '@/utils/rolesUtils';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';
import { MODULES_NAMES } from '@/store/names/modules.name';

export default defineComponent({
  name: 'BaseModelCard',

  components: {
    BaseIconButton,
    ModelLabel,
    AdditionalInfoDropdown,
    BaseCheckbox,
    BaseAuthCardImg
  },

  props: {
    data: {
      type: Object as PropType<TModelData>,
      required: true
    },
    additionalInfo: {
      type: Array as PropType<TAddInfo[]>,
      default: []
    },
    additionalTechInfo: {
      type: Array as PropType<TAddInfo[]>,
      default: []
    },
    additionalAttr: {
      type: Object as PropType<TAddInfo>
    }
  },

  emits: ['clickCompare'],

  setup(props, { emit }) {
    const addInfoRef = ref();
    const addTechInfoRef = ref();
    const modelsHttpService = new ModelsHttpService();
    const { state, dispatch } = useStore();

    const cardBlockClasses = computed(() => {
      return 'model-card-block p-col p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2';
    });

    const toggleDropdown = () => {
      addTechInfoRef.value.addInfoRef.setInVisible();
      addInfoRef.value.addInfoRef.isVisible ? addInfoRef.value.addInfoRef.setInVisible() : addInfoRef.value.addInfoRef.setVisible();
    };

    const toggleTechDropdown = () => {
      addInfoRef.value.addInfoRef.setInVisible();
      addTechInfoRef.value.addInfoRef.isVisible ? addTechInfoRef.value.addInfoRef.setInVisible() : addTechInfoRef.value.addInfoRef.setVisible();
    };

    const getAuthImageForCard = computed(() => {
      if (props.data.modelFileFormat?.id === ModelFileFormats.PRC) {
        return require('@/assets/images/PRC.svg');
      }
      return getAuthImage(getModelImageUrl(props.data.id!));
    });

    const cardClasses = computed(() => {
      return `model-card-content
        ${(props.data.status === ModelStatus.FAILED) && 'failed-card'}
        ${(props.data.status === ModelStatus.PROCESSING || props.data.status === ModelStatus.NEW) && 'processing-card'}`;
    });

    const clickCompare = () => {
      emit('clickCompare', { id: props.data.id, uuid: props.data.modelUuid });
    };

    const resetPipeline = async(id: number) => {
      try {
        await modelsHttpService.restartPipeline(id);
        appendToastMessage('Обработка началась', 'success');
      } catch (e) {
        console.error(e);
        appendToastMessage('Ошибка');
      }
    };

    const deleteModel = async(id: number) => {
      const isDelete = confirm('Вы действительно хотите удалить модель?');
      if (!isDelete) {
        return;
      }
      let modelTypeId: RegisteredModelTypes | RegisteredModelTypes[] = props.data.registeredModelType!.id;
      if ((modelTypeId === RegisteredModelTypes.UTILITY_MODEL) || (modelTypeId === RegisteredModelTypes.INVENTION)) {
        modelTypeId = [RegisteredModelTypes.UTILITY_MODEL, RegisteredModelTypes.INVENTION];
      } else {
        modelTypeId = [modelTypeId];
      }
      try {
        await dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.DELETE_MODEL}`, {
          id: id,
          modelType: modelTypeId
        });
        appendToastMessage('Модель успешно удалена', 'success');
      } catch (err) {
        console.error(err);
        appendToastMessage('Ошибка сервера');
      }
    };

    return {
      deleteModel,
      isAdmin,
      state,
      addTechInfoRef,
      getTypeNameByModelType,
      ModelStatus,
      ModelFileFormats,
      cardBlockClasses,
      resetPipeline,
      getModelValue,
      clickCompare,
      addInfoRef,
      toggleDropdown,
      toggleTechDropdown,
      RegisteredModelTypes,
      ROUTE_NAMES,
      cardClasses,
      getAuthImageForCard
    };
  }
});
