<template>
  <BaseModelCard
    :data="data"
    :additionalInfo="additionalInfo"
    :additional-tech-info="additionalTechInfo"
    @clickCompare="clickCompare($event)"
  />
</template>

<script src="./ModelCardInvention.ts" lang="ts"/>
<style src="./ModelCardInvention.scss" lang="scss" scoped/>
