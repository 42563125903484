<template>
  <BaseTable
    :columns="columns"
    :rows="rows"
    ref="modelTableRef"
    :is-loading="state.models.isLoadingList"
    class-name="models-table"
  >
    <template #row="{ data, index }">
      <tr>
        <td
          :class="`preview-cell ${data.cells[0].status === ModelStatus.PROCESSING && 'processing-cell'}
          ${data.cells[0].status === ModelStatus.FAILED && 'failed-cell'}`"
        >
          <div class="label-wrapper">
            <ModelLabel
              :model-type-id="data.cells[0].registeredModelType.id"
            />
            <div
              v-if="data.cells[0].variantId !== null"
              class="version-label"
            >
              {{ data.cells[0].variantId }}
            </div>
          </div>
          <div
            class="preview-wrapper"
          >
            <router-link
              target="_blank"
              :to="{ name: ROUTE_NAMES.PAGE_MODEL_VIEW, params: { id: data.cells[0].modelUuid } }"
              v-if="!(data.cells[0].status === ModelStatus.FAILED || data.cells[0].status === ModelStatus.PROCESSING || data.cells[0].status === ModelStatus.NEW)"
            >
              <BaseAuthCardImg :data="data.cells[0]"/>
            </router-link>
            <BaseAuthCardImg
              :data="data.cells[0]"
              v-else
            />

          </div>
        </td>
        <td>
          <router-link
            class="request-link"
            target="_blank"
            :to="{ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: data.cells[1].requestId }}"
          >
            {{ getModelValue(data.cells[1].requestId) }}
          </router-link>
        </td>
        <td>
          {{ getModelValue(data.cells[2].patentId) }}
        </td>
        <td>
          {{ createdAt(data.cells[3].createdAt) }} МСК
        </td>
        <td>
          <div
            v-if="data.cells[3].status === ModelStatus.CONVERTED"
            class="buttons-cell"
          >
            <BaseIconButton
              v-if="!(data.cells[3].modelFileFormat.id === ModelFileFormats.PRC ||
              data.cells[3].registeredModelType.id === RegisteredModelTypes.PARTICLE)"
              tool-tip-text="Перейти к поиску"
              @clicked="clickCompare(data.cells[3])"
            >
              <template v-slot:button-image>
                <img alt="img" src="@/assets/icons/search_models.svg">
              </template>
            </BaseIconButton>
            <BaseIconButton
              tool-tip-text="Перейти к просмотру"
              :href="{ name: ROUTE_NAMES.PAGE_MODEL_VIEW, params: { id: data.cells[3].modelUuid } }"
              is-target-blank
            >
              <template v-slot:button-image>
                <img alt="img" src="@/assets/icons/open_model.svg">
              </template>
            </BaseIconButton>
          </div>
        </td>
        <td class="info-cell">
          <div
            class="button-wrapper"
            @click="toggleAddInfo(index)"
            v-tooltip.top="'О заявке'"
          >
            <i class="pi pi-info-circle"></i>
          </div>
          <div
            class="button-wrapper"
            @click="toggleAddTechInfo(index)"
            v-tooltip.top="'Техническая информация'"
          >
            <img alt="img" src="@/assets/icons/tech_info.svg"/>
          </div>
        </td>
      </tr>
    </template>
    <template #additional-info="{ data }">
      <div class="p-grid">
        <template
          v-for="(item, index) in additionalInfo(data.cells[4])"
          :key="index+data.cells[4].id"
        >
          <div
            class="p-col-4 additional-info-block"
            v-if="!!item.value"
          >
            <div class="label">{{ item.label }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </template>
      </div>
    </template>
    <template #additional-info-second="{ data }">
      <div class="p-grid">
        <template
          v-for="(item, index) in additionalTechInfo(data.cells[4])"
          :key="index+data.cells[4].id"
        >
          <div
            class="p-col-4 additional-info-block"
            v-if="!!item.value"
          >
            <div class="label">{{ item.label }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </template>
      </div>
    </template>
  </BaseTable>
</template>

<script src="./ModelsTable.ts" lang="ts"/>
<style src="./ModelsTable.scss" lang="scss"/>
