<template>
  <div class="mkpo-filters-wrapper">
    <BaseForm>
      <template v-slot:form-content>
        <div class="mkpo-inputs-block">
          <MkpoDropdownGroup
            :values="values"
            :possible-values-prop="possibleValues"
            @changed="changeMkpoValues"
            ref="mkpoGroupRef"
            :is-watcher-start="isLoaded"
          />
        </div>
        <div class="mkpo-buttons-block">
          <BaseButton
            label="Очистить все фильтры"
            class-name="reset-btn"
            @clicked="resetForm"
          />
          <BaseButton
            outlined
            label="Применить"
            class-name="submit-btn"
            @clicked="handleSubmitForm"
          />
        </div>
      </template>
    </BaseForm>
  </div>
</template>

<script src="./MkpoFiltersForm.ts" lang="ts"/>
<style src="./MkpoFiltersForm.scss" lang="scss"/>
