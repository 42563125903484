import { defineComponent, onMounted } from 'vue';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { useStore } from 'vuex';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import { ShowType } from '@/store/modules/paginator/state';

export default defineComponent({
  name: 'ChangeShowTypeButton',

  components: {
    BaseIconButton
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const { state, commit } = useStore();

    const storageName = 'activeShowType';

    const changeShowType = (type: ShowType) => {
      commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_SHOW_TYPE}`, type);
      localStorage.setItem(storageName, type.toString());
    };

    const isActiveShowedType = (type: ShowType) => {
      return state.paginator.showType === type;
    };

    onMounted(() => {
      const activeType = localStorage.getItem(storageName);
      activeType && commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_SHOW_TYPE}`, +activeType);
    });

    return {
      isActiveShowedType,
      changeShowType,
      ShowType
    };
  }
});
