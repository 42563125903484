import { defineComponent, onMounted, ref } from 'vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BasePopOver from '@/components/BaseComponents/BasePopOver/BasePopOver.vue';
import MkpoFiltersForm from '@/components/Model/PageIndustrialList/components/MkpoFilters/components/MkpoFiltersForm/MkpoFiltersForm.vue';

export default defineComponent({
  name: 'MkpoFilters',
  components: {
    BaseButton,
    BasePopOver,
    MkpoFiltersForm
  },
  setup() {
    const mkpoPopOverRef = ref();
    const isLoaded = ref(false);

    const togglePopOver = () => {
      return mkpoPopOverRef?.value?.BasePopOverRef.toggle(event);
    };

    onMounted(() => {
      isLoaded.value = true;
    });

    return {
      isLoaded,
      togglePopOver,
      mkpoPopOverRef
    };
  }
});
